import {useSettings} from '@wix/yoshi-flow-editor/tpa-settings/react'
import React from 'react'
import {ChevronRight} from 'wix-ui-icons-common'
import Location from 'wix-ui-icons-common/LocationSmall'
import {TEXT_BUTTON_PRIORITY, TextButton} from 'wix-ui-tpa/TextButton'
import classNames from 'classnames'
import {DH} from '../../../data-hooks'
import settingsParams from '../../../settingsParams'
import {Tags} from '../../filters/tags'
import {ItemDivider} from '../divider'
import {Time} from '../time'
import {
  getScheduleItemDescription,
  isScheduleItemCanceled,
  isScheduleItemWithIndication,
} from '../../../selectors/schedule'
import {Indications} from '../../indications'
import {useWidgetProps} from '../../../hooks/widget-props'
import {useModal} from '../../../hooks/modal'
import s from './mobile.scss'
import {classes as c} from './mobile.st.css'
import {Bookmark} from './bookmark'
import type {ItemProps} from '.'

export const MobileItem = ({item, showDivider}: ItemProps) => {
  const {insideModal} = useModal()
  const {toggleScheduleItemModal, readDescriptionClicked, t} = useWidgetProps()

  const {formattedUpdatedDate, formattedTimeSlot, formattedDuration, name, location, tags, id, bookmarked} = item
  const settings = useSettings()
  const showDuration = settings.get(settingsParams.showDuration)
  const descriptionButtonText = settings.get(settingsParams.readMore)
  const description = getScheduleItemDescription(item)
  const canceled = isScheduleItemCanceled(item)

  const handleReadMore = () => {
    readDescriptionClicked({itemId: item.id})
    toggleScheduleItemModal(item)
  }

  return (
    <li className={classNames({[c.insideModal]: insideModal, [c.default]: !insideModal})}>
      <Indications canceled={canceled} t={t} formattedUpdatedDate={formattedUpdatedDate} />
      <div
        className={classNames(s.container, {
          [s.itemWithIndication]: isScheduleItemWithIndication(item),
        })}
        data-hook={DH.MOBILE_SCHEDULE_ITEM}
      >
        <div className={s.timeContainer}>
          <Time className={s.timeSlot} data-hook={DH.SCHEDULE_ITEM_DATETIME} dateTime={formattedTimeSlot}>
            {formattedTimeSlot}
          </Time>
          <Bookmark itemId={id} bookmarked={bookmarked} />
        </div>
        {showDuration && (
          <div className={s.duration} data-hook={DH.SCHEDULE_ITEM_DURATION}>
            {formattedDuration}
          </div>
        )}
        {insideModal ? (
          <h4 className={s.title} data-hook={DH.SCHEDULE_ITEM_NAME}>
            {name}
          </h4>
        ) : (
          <h3 className={s.title} data-hook={DH.SCHEDULE_ITEM_NAME}>
            {name}
          </h3>
        )}
        {location && (
          <div className={s.location} data-hook={DH.LOCATION}>
            <Location data-hook={DH.LOCATION_ICON} aria-label={t('aria.scheduleItem.location')} role="img" />
            <span>{location}</span>
          </div>
        )}
        {tags && <Tags t={t} tags={tags} className={s.tags} />}
        {description && (
          <div className={s.buttonContainer}>
            <TextButton
              className={c.readMore}
              priority={TEXT_BUTTON_PRIORITY.primary}
              data-hook={DH.DESCRIPTION_BUTTON}
              suffixIcon={<ChevronRight />}
              onClick={handleReadMore}
            >
              {descriptionButtonText}
            </TextButton>
          </div>
        )}
      </div>
      {showDivider && <ItemDivider />}
    </li>
  )
}
