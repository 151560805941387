import * as React from 'react'
import {Dialog} from 'wix-ui-tpa/Dialog'
import Location from 'wix-ui-icons-common/LocationSmall'
import {Text} from 'wix-ui-tpa/Text'
import {useSettings} from '@wix/yoshi-flow-editor/tpa-settings/react'
import classNames from 'classnames'
import {useEnvironment} from '@wix/yoshi-flow-editor'
import {DH} from '../../../data-hooks'
import {Tags} from '../../filters/tags'
import settingsParams from '../../../settingsParams'
import ms from '../../schedule/item/mobile.scss'
import {classes as descriptionClasses} from '../../schedule/description/description.st.css'
import {classes as modalClasses} from '../modal.st.css'
import {getScheduleItemDescription, isScheduleItemCanceled} from '../../../selectors/schedule'
import {Indications} from '../../indications'
import {Bookmark} from '../../schedule/item/bookmark'
import {useWidgetProps} from '../../../hooks/widget-props'
import {ModalContext, ModalType} from '../../../hooks/modal'
import {classes} from './schedule-item.st.css'
import {ScheduleItemProps} from '.'

export const ScheduleItem = ({item}: ScheduleItemProps) => {
  const {host, t, toggleScheduleItemModal} = useWidgetProps()
  const {isMobile} = useEnvironment()
  const settings = useSettings()
  const showDuration = settings.get(settingsParams.showDuration)
  const preview = host.viewMode === 'Preview'
  const description = getScheduleItemDescription(item)
  const canceled = isScheduleItemCanceled(item)

  return (
    <ModalContext.Provider value={{insideModal: true, modalType: ModalType.SCHEDULE_ITEM}}>
      <Dialog
        className={classNames(modalClasses.root, modalClasses.wiredModal, {
          [modalClasses.previewMode]: preview,
          [modalClasses.mobile]: isMobile,
        })}
        contentClassName={classes.modalContent}
        isOpen={true}
        onClose={() => toggleScheduleItemModal(null)}
        closeButtonAriaLabel={t('aria.dialog.close')}
      >
        <div className={classes.container}>
          <Indications canceled={canceled} t={t} formattedUpdatedDate={item.formattedUpdatedDate} />
          <div className={ms.timeContainer}>
            <div className={ms.timeSlot}>{item.formattedTimeSlot}</div>
            <Bookmark itemId={item.id} bookmarked={item.bookmarked} />
          </div>
          {showDuration && <div className={ms.duration}>{item.formattedDuration}</div>}
          <h2 className={ms.title}>{item.name}</h2>
          {item.location && (
            <div className={ms.location} data-hook={DH.LOCATION}>
              <Location data-hook={DH.LOCATION_ICON} aria-label={t('aria.scheduleItem.location')} role="img" />
              <span>{item.location}</span>
            </div>
          )}
          {item.tags && <Tags t={t} tags={item.tags} className={ms.tags} />}
          {description && (
            <Text
              className={classNames(descriptionClasses.blackText, descriptionClasses.text)}
              data-hook={DH.DESCRIPTION}
            >
              <div dangerouslySetInnerHTML={{__html: description}} />
            </Text>
          )}
        </div>
      </Dialog>
    </ModalContext.Provider>
  )
}
